<h3 class="ui-portal-title">
  Saved Searches
</h3>

@for (item of items; track item.id) {
  <a class="item" [routerLink]="item.url" [queryParams]="item.queryParams">
    <h3>
      {{ item.name }}
      <button class="btn-transparent" [ui-tooltip]="item.subscription ? 'Unsubscribe' : 'Subscribe'" (click)="toggleSubscription(item, $event)">
        <img [src]="'/assets/img/' + (item.subscription ? 'icon-bell-filled.svg' : 'icon-bell.svg')">
      </button>
      <button class="btn-transparent" ui-tooltip="Delete" (click)="onDeleteClick(item, $event)">
        <img src="/assets/img/delete.svg">
      </button>
    </h3>
    <p>
      {{ item.criteria }}
    </p>
    <p>
      Products Found: {{ item.found }}
    </p>
  </a>
}
